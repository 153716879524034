export enum FirestoreCollection {
  Notifications = 'notifications',
}

export enum NotificationSource {
  Inventory = 'Inventory',
  OrderManagement = 'Order Management',
  Publish = 'Publish',
  OfflinePos = 'Offline Pos',
  Integrations = 'Integrations',
  AppAuth = 'app_auth',
  Pos = 'Pos',
  Marketing = 'Marketing',
  Subscriptions = 'Subscriptions',
  RewaaOnboard = 'Rewaa Onboard',
  PosExport = 'Pos Export',
  AccountTransactionsExport = 'Account Transactions Export',
  ExpenseExport = 'Expense Export',
  TrialBalanceExport = 'Trial Balance Export',
  IncomeStatementExport = 'Income Statement Export',
  BalanceSheetExport = 'Balance Sheet Export',
  GeneralLedgerExport = 'General Ledger Export',
  ExpenseCategoriesExport = 'Expense Categories Export',
  AccountReceivableExport = 'Account Receivable Export',
  AccountPayableExport = 'Account Payable Export',
}

export enum NotificationType {
  ToggleLink = 'toggle-link-status',
  ToggleLinkBatchUpdate = 'toggle-link-batch-update',
  ProductChanges = 'products_changes',
  ProductVariantFile = 'products_variant_file',
  ClearOfflineData = 'clear_offline_data',
  PromotionChanges = 'promotion_changes',
  RewaaOnboard = 'rewaa_onboard',
  Success = 'success',
  BankTransferVerified = 'bank_transfer_verified',
  BankTransferPending = 'bank_transfer_verification_pending',
  PayByMobileCompleted = 'mobile_payment_completed',
  PayByMobilePending = 'mobile_payment_pending',
  TrialEndReminder = 'subscription_trial_remainder',
  ActiveRegisterSession = 'ActiveRegisterSession',
  CustomerChanges = 'customer_changes',
  OfflineCustomerFile = 'offline_customer_file',
  UserCache = 'user_cache',
  OfflineInvoiceSynced = 'offline-invoice-synced',
  CustomerExportFile = 'customer_export_file',
  RegisterForceClose = 'register-force-close',
  GenericFileExport = 'generic_file_export',
  MerchantLoggedOut = 'merchant_logged_out',
  ResolveOrderPendingProducts = 'resolve-order-pending-products',
}

export enum NotificationViewType {
  Banner = 'banner',
  TrialEndReminder = 'trial_end_reminder',
  UserCache = 'user_cache',
}

import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LocalStorageService } from './core/services/local-storage.service';
import { LocalStorageKey } from './shared/constants';
import { environment } from '../environments/environment';

export interface MetaInfo {
  title: string;
  description: string;
}

interface LanguageConfig {
  default: MetaInfo;
  routes: Record<string, MetaInfo>;
}

@Injectable()
export class SeoService {
  private readonly DEFAULT_IMAGE =
    'https://www.rewaatech.com/wp-content/uploads/2021/09/Arabic-MIMS-Photo.svg';

  private readonly PRODUCTION_URL = 'https://platform.rewaatech.com';

  private readonly META_INFO_MAP: Record<string, LanguageConfig> = {
    ar: {
      default: {
        title: 'منصة رواء',
        description:
          'سجّل الدخول لحسابك في منصة رِواء وابدأ في إدارة تجارتك وعملياتك بكل سهولة، وتابع مبيعاتك وأرباحك من مكان واحد. سجّل الآن!',
      },
      routes: {
        '/': {
          title: 'تسجيل الدخول | رِواء',
          description:
            'سجّل الدخول لحسابك في منصة رِواء وابدأ في إدارة تجارتك وعملياتك بكل سهولة، وتابع مبيعاتك وأرباحك من مكان واحد. سجّل الآن!',
        },
        '/login': {
          title: 'تسجيل الدخول في المنصة | رِواء',
          description:
            'سجّل دخولك إلى منصة رِواء واستمتع باستخدام كافة المميزات لتسهيل إدارة تجارتك ومتابعة مبيعاتك وأرقامك بكل دقة. سجّل الآن!',
        },
        '/register': {
          title: 'إنشاء حساب | رِواء',
          description:
            'أنشئ حسابك في منصة رِواء وابدأ رحلتك معنا بإدارة ذكية لتجارتك ومتابعة دقيقة لأرقامك، واكتشف حلولنا الذكية في المحاسبة والكاشير والمخزون. افتح حسابك الآن!',
        },
      },
    },
    en: {
      default: {
        title: 'Rewaa Platform',
        description:
          'Log in to your Rewaa account and start managing your business and operations with ease. Track your sales and profits all from one place. Log in now!',
      },
      routes: {
        '/en/login': {
          title: 'Rewaa Platform | Login',
          description:
            'Log in to your Rewaa account and start managing your business and operations with ease. Track your sales and profits all from one place. Log in now!',
        },
        '/en/register': {
          title: 'Rewaa Registration | Create Your Account',
          description:
            'Create your account on Rewaa and start your journey with smart business management. Explore our solutions for accounting, POS, and inventory. Sign up now!',
        },
      },
    },
  };

  constructor(
    private meta: Meta,
    private title: Title,
    private router: Router,
    private localStorageService: LocalStorageService,
  ) {}

  init(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.handleRouteChange();
      });
  }

  private get language(): string {
    return (
      this.localStorageService.getItem<string>(LocalStorageKey.Language) || 'ar'
    );
  }

  private handleRouteChange(): void {
    if (environment.applicationUrl === this.PRODUCTION_URL) {
      const metaInfo = this.getMetaInfoForRoute(this.router.url);
      this.updateMetaTags(metaInfo);
    } else {
      this.setNonProductionMetaInfo();
    }
  }

  private getMetaInfoForRoute(route: string): MetaInfo {
    const lang = this.language;
    const parsedRoute = lang === 'en' ? `/en${route}` : route;
    const langConfig = this.META_INFO_MAP[lang];

    return langConfig.routes[parsedRoute] || langConfig.default;
  }

  private updateMetaTags(metaInfo: MetaInfo): void {
    const baseMetaTags = {
      title: metaInfo.title,
      description: metaInfo.description,
      'og:title': metaInfo.title,
      'og:description': metaInfo.description,
      'og:url': 'https://www.rewaatech.com/',
      'og:image': this.DEFAULT_IMAGE,
      'og:locale': 'ar_AR',
    };

    this.title.setTitle(metaInfo.title);

    Object.entries(baseMetaTags).forEach(([key, value]) => {
      if (key === 'description') {
        this.meta.updateTag({ name: key, content: value });
      } else if (key.startsWith('og:')) {
        this.meta.updateTag({ property: key, content: value });
      }
    });
  }

  private setNonProductionMetaInfo(): void {
    this.title.setTitle('Rewaa Platform');
    ['robots', 'googlebot'].forEach((bot) => {
      this.meta.updateTag({ name: bot, content: 'noindex' });
    });
  }

  public updateMetaInfo(metaInfo: Partial<MetaInfo>): void {
    const currentMeta = this.getMetaInfoForRoute(this.router.url);
    this.updateMetaTags({ ...currentMeta, ...metaInfo });
  }
}

<div
  class="rw__datatable-v3 flex flex-column"
  [ngClass]="{ 'rw__datatable-v3-resize': resizeColumn, 'rw__datatable-v3-mobile': isMobileResolution }"
>
  <p-table
    #datatable
    [value]="values"
    [columns]="selectedColumns"
    [rows]="numberOfResults"
    [responsive]="true"
    responsiveLayout="stack"
    [scrollable]="!isMobileResolution"
    [resizableColumns]="resizeColumn"
    [loading]="loading"
    [lazy]="true"
    [selection]="selectedRows"
    (onLazyLoad)="handleLazyLoading($event)"
    dataKey="id"
    [rowTrackBy]="trackByFunc"
    (selectionChange)="onSelectionChange($event)"
  >
    <ng-template pTemplate="header" let-columns>
      <tr id="datatable-table-header" class="header-row">
        <ng-container *ngIf="isMobileResolution && showCheckboxes">
          <div class="bg-white rw__p-5 rw__checkbox rw__checkbox-md" [ngClass]="{'border-1 rounded-6': isMobileResolution}">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </div>
        </ng-container>

        <th
          pFrozenColumn
          *ngIf="columns[0].frozen"
          [ngClass]="{
            leftFrozenColumn: leftFrozenWidth,
            'rw__filter-active': columns[0].filterCount
          }"
          [ngStyle]="{
            width: leftFrozenWidth ? leftFrozenWidth + 'px' : 'auto',
            'max-width': leftFrozenWidth ? leftFrozenWidth + 'px' : 'auto'
          }"
          [frozen]="leftFrozenWidth ? true : false"
          [alignFrozen]="leftFrozenWidth ? 'left' : ''"
        >
          <div
            *ngIf="showCheckboxes"
            class="rw__me-7 rw__checkbox rw__checkbox-md"
          >
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </div>
          <div class="flex flex-column">
            <div class="rw__fs-10">{{ columns[0].name | translate }}</div>
            <div *ngIf="columns[0].captionText" class="rw__fs-10 text-grey-60 fw-400 rw__mt-1">{{ columns[0].captionText | translate }}</div>
          </div>
          <div *ngIf="columns[0].tooltipText">
            <rw-button
              [type]="'grey-transparent'"
              [size]="'md'"
              [label]="''"
              [icon]="'fa-regular fa-info-circle'"
              [tracker]="'dummy'"
              [badge]="0"
              [tooltipText]="columns[0].tooltipText | translate"
              [tooltipPosition]="'bottom'"
              [disabled]="false"
              [loading]="false"
              [rounded]="true"
              [responsive]="false"
            ></rw-button>
          </div>
          <ng-container [ngTemplateOutlet]="filter" [ngTemplateOutletContext]="{ col: columns[0] }"></ng-container>
        </th>
        <ng-template
          ngFor
          [ngForOf]="columns"
          let-col
          let-i="index"
        >
          <th
            *ngIf="!col.frozen"
            [ngClass]="{ 'rw__filter-active': col.filterCount }"
          >
          <div class="flex flex-column">
            <div class="rw__fs-10">{{ col.name | translate }}</div>
            <div *ngIf="col.captionText" class="rw__fs-10 text-grey-60 fw-400 rw__mt-1">{{ col.captionText | translate }}</div>
          </div>
            <div *ngIf="col.tooltipText">
              <rw-button
                [type]="'grey-transparent'"
                [size]="'md'"
                [label]="''"
                [icon]="'fa-regular fa-info-circle'"
                [tracker]="'dummy'"
                [badge]="0"
                [tooltipText]="col.tooltipText | translate"
                [tooltipPosition]="'bottom'"
                [disabled]="false"
                [loading]="false"
                [rounded]="true"
                [responsive]="false"
              ></rw-button>
            </div>
            <ng-container [ngTemplateOutlet]="filter" [ngTemplateOutletContext]="{ col: col }"></ng-container>
          </th>
        </ng-template>
        <th
          pFrozenColumn
          *ngIf="
            columns[columns.length - 1].frozen
          "
          [ngClass]="{
            rightFrozenColumn: rightFrozenWidth,
            'rw__filter-active': columns[columns.length - 1].filterCount,
            'col-customized': showCustomizedOverlay,
            'left-100': columns.length <= 2
          }"
          [ngStyle]="{
            width: rightFrozenWidth ? rightFrozenWidth + 'px' : 'auto',
            'max-width': rightFrozenWidth ? rightFrozenWidth + 'px' : 'auto'
          }"
          [frozen]="rightFrozenWidth ? true : false"
          [alignFrozen]="rightFrozenWidth ? 'right' : ''"
        >
          <div class="d-flex align-items-center">
            <div *ngIf="columns[columns.length - 1].tooltipText && columns[columns.length - 1].tooltipTextIconPosition === 'left'">
              <rw-button
                [type]="'grey-transparent'"
                [size]="columns[columns.length - 1].tooltipTextIconSize ? columns[columns.length - 1].tooltipTextIconSize : 'md'"
                [label]="''"
                [icon]="'fa-regular fa-info-circle'"
                [tracker]="'dummy'"
                [badge]="0"
                [tooltipText]="columns[columns.length - 1].tooltipText | translate"
                [tooltipPosition]="'bottom'"
                [disabled]="false"
                [loading]="false"
                [rounded]="true"
                [responsive]="false"
              ></rw-button>
            </div>
            <div class="flex flex-column">
              <div (click)="op.toggle($event)" class="rw__fs-10 customize-col-label">{{ columns[columns.length - 1].name | translate }}</div>
              <div *ngIf="columns[columns.length - 1].captionText"
              class="rw__fs-10 text-grey-60 fw-400 rw__mt-1">{{ columns[columns.length - 1].captionText | translate }}</div>
            </div>
            <div *ngIf="columns[columns.length - 1].tooltipText && columns[columns.length - 1].tooltipTextIconPosition !== 'left'">
              <rw-button
                [type]="'grey-transparent'"
                [size]="columns[columns.length - 1].tooltipTextIconSize ? columns[columns.length - 1].tooltipTextIconSize : 'md'"
                [label]="''"
                [icon]="'fa-regular fa-info-circle'"
                [tracker]="'dummy'"
                [badge]="0"
                [tooltipText]="columns[columns.length - 1].tooltipText | translate"
                [tooltipPosition]="'bottom'"
                [disabled]="false"
                [loading]="false"
                [rounded]="true"
                [responsive]="false"
              ></rw-button>
            </div>
            <ng-container
              [ngTemplateOutlet]="action"
              [ngTemplateOutletContext]="{ col: columns[columns.length - 1] }"
              *ngIf="columns[columns.length - 1].prop === 'action'"
            ></ng-container>
          </div>
        </th>
        <ng-template #action let-col="col">
          <ng-template [ngIf]="col.actionType === tableActionTypes.ClearAll">
            <i
              class="fa-regular fa-filter-slash pointer"
              (click)="clearAllFilters(datatable)"
              [attr.pendo-tracker]="col.pendoTag"
            ></i>
          </ng-template>
          <ng-template [ngIf]="col.actionType === tableActionTypes.Customize">
            <i
              class="fa-regular fa-table-list rw__ms-3 pointer"
              (click)="op.toggle($event)"
              [attr.pendo-tracker]="col.pendoTag"
            ></i>
          </ng-template>
        </ng-template>
        <p-overlayPanel
          #op
          styleClass="table-custom-overlay"
          appendTo="body"
          (onShow)="showCustomizedOverlay = true"
          (onHide)="showCustomizedOverlay = false"
        >
          <ng-template pTemplate>
            <p-listbox
              [options]="customizeColumnOptions"
              dataKey="prop"
              optionLabel="name"
              [(ngModel)]="selectedColumns"
              [multiple]="true"
              [listStyle]="{'max-height':'250px'}"
              [checkbox]="true"
              [filter]="true"
              optionDisabled="frozen"
              (onClick)="selectedColumnsChanged($event)"
              listStyleClass="customize-column-list"
            >
              <ng-template
                let-option
                pTemplate="item"
              >
                <div>
                  <span>{{ option.name | translate }}</span>
                </div>
              </ng-template>
            </p-listbox>
          </ng-template>
        </p-overlayPanel>
        <ng-template #filter let-col="col">
          <div *ngIf="col.filter">
            <rw-table-filter [dateRangeLimitInMonths]="dateRangeLimitInMonths" [loading]="loading" [col]="col" [tableFilters]="tableFilters" [dateRanges]="dateRanges" [autoCompleteSearches]="autoCompleteSearches" (filterApplied)="tableFilterApplied($event)"></rw-table-filter>
          </div>
        </ng-template>
      </tr>
    </ng-template>
    <ng-template
      pTemplate="body"
      let-columns="columns"
      let-data
    >
      <tr id="datatable-table-body"
          [rwDatatableRow]="data"
          [newDataHighlightColumnName]="newDataHighlightColumnName"
          [newDataHighlightMaxTime]="newDataHighlightMaxTime"
          [isSelected]="isRowSelected(data)">
        <td
          *ngIf="columns[0].frozen"
          pFrozenColumn
          [ngClass]="{ 'leftFrozenColumn cursor-pointer': !isMobileResolution && leftFrozenWidth }"
          [ngStyle]="{
            width: !isMobileResolution && leftFrozenWidth ? leftFrozenWidth + 'px' : 'auto',
            'max-width': !isMobileResolution && leftFrozenWidth ? leftFrozenWidth + 'px' : 'auto'
          }"
          [frozen]="leftFrozenWidth ? true : false"
          [alignFrozen]="leftFrozenWidth ? 'left' : ''"
        >
          <div
            *ngIf="!isMobileResolution && showCheckboxes"
            class="rw__me-7 rw__checkbox rw__checkbox-md"
          >
            <p-tableCheckbox [value]="data"></p-tableCheckbox>
          </div>
          <ng-container [ngTemplateOutlet]="value" [ngTemplateOutletContext]="{ col: columns[0] }"></ng-container>
        </td>
        <ng-template
          ngFor
          [ngForOf]="selectedColumns"
          let-col
          let-i="index"
        >
          <p-overlayPanel #overlayCell styleClass="custom-cell">
            <ul class="max-h-14rem overflow-y-auto w-15rem rw__mb-0 rw__ps-6">
              <li *ngFor="let value of data[col.overlayCellKey]" class="rw__h-6 fw-400">
                {{ value }}
              </li>
            </ul>
          </p-overlayPanel>
          <td
            *ngIf="!col.frozen"
            [ngClass]="{'cursor-pointer': col?.link, 'custom-cell': data[col.overlayCellKey]?.length > 0}"
            (click)="data[col.overlayCellKey]?.length > 0 ? overlayCell.toggle($event) : null"
          >
            <ng-container [ngTemplateOutlet]="value" [ngTemplateOutletContext]="{ col: col }"></ng-container>
          </td>
        </ng-template>
        <td
          *ngIf="columns[columns.length - 1].frozen && columns[columns.length - 1].actionType"
          pFrozenColumn
          [ngClass]="{ rightFrozenColumn: !isMobileResolution && rightFrozenWidth,
          'left-100': columns.length <= 2 }"
          [ngStyle]="{
            width: !isMobileResolution && rightFrozenWidth ? rightFrozenWidth + 'px' : 'auto',
            'max-width': !isMobileResolution && rightFrozenWidth ? rightFrozenWidth + 'px' : 'auto'
          }"
          [frozen]="rightFrozenWidth ? true : false"
          [alignFrozen]="rightFrozenWidth ? 'right' : ''"
        >
          <div class="flex-grow-1">
            <ng-container
              [ngTemplateOutlet]="action"
              [ngTemplateOutletContext]="{ col: columns[columns.length - 1] }"
            >
            </ng-container>
          </div>
        </td>
        <ng-template #action let-col="col">
          <div class="flex-grow-1" [ngClass]="{'d-flex justify-content-between align-items-center' : isMobileResolution}">
            <div class="rw__checkbox rw__checkbox-md" *ngIf="isMobileResolution && showCheckboxes">
              <p-tableCheckbox [value]="data"></p-tableCheckbox>
            </div>
            <div
            *ngIf="col.prop === 'action'"
            class="d-flex align-items-center justify-content-center"
          >
            <span *ngFor="let item of col.items">
              <span *ngIf="item.itemType !== itemTypes.Button && !(item.hidden && item.hidden(data))">
                <button
                  rwPermission
                  [permission]="item.permission"
                  action="force-hide"
                  pButton
                  pRipple
                  type="button"
                  [pTooltip]="item.name | translate"
                  tooltipPosition="top"
                  tooltipStyleClass="rw__tooltip"
                  [icon]="item.iconClass"
                  class="rw__button rw__button-xs rw__button-icon rounded-circle p-button-text p-button-plain"
                  [attr.pendo-tracker]="item?.tracker"
                  (click)="actionItemClick($event, appMenu, item, data)"
                  [disabled]="item.disabled | resolveItemDisabled: data"
                ></button>
                <p-overlayPanel #appMenu appendTo="body" styleClass="table-custom-overlay">
                  <ng-template pTemplate>
                    <p-listbox [options]="rowItemChildren" optionLabel="name" optionDisabled="disabled" styleClass="rw__py-2 border-1" [listStyle]="{'width': '200px' , 'max-height':'250px'}">
                      <ng-template let-menuItem pTemplate="item">
                        <div class="flex gap-2 w-full" [pTooltip]="menuItem.tooltip | translate" tooltipPosition="left"
                        tooltipStyleClass="rw__tooltip" (click)="rowItemClicked(appMenu, menuItem, data)" [attr.pendo-tracker]="menuItem?.tracker">
                            <i [class]="menuItem.iconClass + ' rw__fs-7'" *ngIf="menuItem.iconClass"></i>
                            <div>{{menuItem.name}}</div>
                        </div>
                    </ng-template>
                    </p-listbox>
                  </ng-template>
                </p-overlayPanel>
              </span>
              <span *ngIf="item.itemType === itemTypes.Button && !(item.hidden && item.hidden(data))">
                <button
                  rwPermission
                  [permission]="item.permission"
                  action="force-hide"
                  pButton
                  pRipple
                  type="button"
                  [pTooltip]="item.name | translate"
                  tooltipPosition="top"
                  tooltipStyleClass="rw__tooltip"
                  [icon]="item.iconClass"
                  class="rw__button"
                  [ngClass]="item.styleClass"
                  [attr.pendo-tracker]="item?.tracker"
                  (click)="actionItemClick($event, appMenu, item, data)"
                  [disabled]="item.disabled | resolveItemDisabled: data"
                >
                  {{item.actionBtnName | resolveItemName: data | translate}}
                </button>
                <p-overlayPanel #appMenu appendTo="body" styleClass="table-custom-overlay">
                  <ng-template pTemplate>
                    <p-listbox [options]="rowItemChildren" optionLabel="name" optionDisabled="disabled" styleClass="rw__py-2 border-1" [listStyle]="{'width': '200px' , 'max-height':'250px'}">
                      <ng-template let-menuItem pTemplate="item">
                        <div class="flex gap-2 w-full" [pTooltip]="menuItem.tooltip | translate" tooltipPosition="left"
                        tooltipStyleClass="rw__tooltip" (click)="rowItemClicked(appMenu, menuItem, data)" [attr.pendo-tracker]="menuItem?.tracker">
                            <i [class]="menuItem.iconClass + ' rw__fs-7'" *ngIf="menuItem.iconClass"></i>
                            <div>{{menuItem.name}}</div>
                        </div>
                    </ng-template>
                    </p-listbox>
                  </ng-template>
                </p-overlayPanel>
              </span>
            </span>
            </div>
          </div>

        </ng-template>
        <ng-template #value let-col="col">
          <div class="p-column-title" [ngClass]="{'hidden': col.frozen && col.showImage}">
            <span> {{ col.name | translate }}</span>
          </div>
          <div
            class="d-flex align-items-center" [ngClass]="{'product-title-container': col.frozen && col.showImage}"
            (click)="
              (col.frozen || col?.link) && col.items[0].handler && col.items[0].handler(data)
            "
          >
            <div
              *ngIf="col.frozen && col.showImage"
              class="border-1 rounded-2 rw__me-4"
            >
              <img
                [src]="data.link || 'assets/images/img-placeholder.svg'"
                height="32"
                width="32"
                [alt]="data.name"
              />
            </div>
            <div [ngSwitch]="col.cellTemplate">
              <div *ngSwitchCase="cellTemplates.Date">
                {{ data[col.prop] | date : 'mediumDate' }}
              </div>
              <div *ngSwitchCase="cellTemplates.DateTime">
                {{ data[col.prop] | date : 'medium' }}
              </div>
              <div *ngSwitchCase="cellTemplates.Boolean">
                {{
                  col.disableValueTranslation ?
                  (data[col.prop] === true || data[col.prop] === 'true' || data[col.prop] === 1
                        ? 'Yes'
                        : 'No')
                    :
                    (data[col.prop] === true || data[col.prop] === 'true' || data[col.prop] === 1
                      ? ('Yes' | translate)
                      : ('No' | translate))
                }}
              </div>
              <div *ngSwitchCase="cellTemplates.BooleanNegate">
                {{ col.disableValueTranslation ?
                  (data[col.prop] ? 'No' : 'Yes')
                  : (data[col.prop] ? ('No' | translate) : ('Yes' | translate))
                  }}
              </div>
              <div *ngSwitchCase="cellTemplates.Numeric" class="numeric-translation">
                {{
                  maxTwoDecimalPlaceNumericValues ?
                    ((data[col.prop] === null ? 0 : data[col.prop]) | number:'1.2-2')
                    :
                    data[col.prop] === null ? 0 : data[col.prop]
                }}
              </div>
              <div *ngSwitchCase="cellTemplates.InnerHtml" class="flex align-items-center">
                <div
                  class="rw__data-val"
                  [ngClass]="{ 'text-primary': (col?.frozen || col?.link) && col?.items?.length}"
                  [innerHtml]="col.disableValueTranslation ? data[col.prop] : ((col.translatePrefix
                      ? col.translatePrefix + '.' + data[col.prop]
                      : data[col.prop]
                    ) | translate)"
                >
                </div>
              </div>
              <div *ngSwitchCase="cellTemplates.Tag" class="flex align-items-center">
                <rw-tag-v2
                  size="sm"
                  [type]="data[col.prop].type"
                  text="{{ col.translatePrefix + '.' + data[col.prop].state | translate }}">
                </rw-tag-v2>
              </div>
              <div *ngSwitchCase="cellTemplates.TaggedMultiSelect" class="flex align-items-center">
                <rw-tag-v2
                  size="sm"
                  [type]="data[col.prop].type"
                  text="{{ data[col.prop].state | translate }}
                  ">
                </rw-tag-v2>
              </div>
              <div *ngSwitchDefault class="flex align-items-center" [ngClass]="cellTemplates.NumericV2 ? 'numeric-translation' : ''">
                <div class="flex flex-column" [ngClass]="{'cursor-default': col.overlayCellKey}">
                <!-- Value Greater then 100 characters will truncate and added (...) with full value in Tooltip -->
                <div
                *ngIf="
                  (col.translatePrefix + '.' + data[col.prop])?.length >=
                    100 || data[col.prop]?.length >= 100
                "
                class="rw__data-val"
                [ngClass]="{ 'text-primary': (col?.frozen || col?.link) && col?.items?.length}"
                tooltipStyleClass="rw__tooltip"
                tooltipPosition="top"
                pTooltip="{{
                  col.disableValueTranslation ? data[col.prop] : ((col.translatePrefix
                    ? col.translatePrefix + '.' + data[col.prop]
                    : data[col.prop]
                  ) | translate)
                }}"
              >
                {{
                  (col.disableValueTranslation ? data[col.prop] : ((col.translatePrefix
                    ? col.translatePrefix + '.' + data[col.prop]
                    : data[col.prop]
                  )| translate) )
                    | slice : 0 : 15
                }}...
              </div>

              <!-- Value Lower then 100 characters -->
              <div
                *ngIf="
                  !isNumber(data[col.prop]) &&
                  ((col.translatePrefix + '.' + data[col.prop])?.length <
                    100 || data[col.prop]?.length < 100); else numericDataField
                "
                class="rw__data-val"
                [ngClass]="{ 'text-primary': (col?.frozen || col?.link) && col?.items?.length}"
              >
                {{
                  col.disableValueTranslation ? data[col.prop] : ((col.translatePrefix
                    ? col.translatePrefix + '.' + data[col.prop]
                    : data[col.prop]
                  ) | translate)
                }}
              </div>

              <ng-template #numericDataField>
                <div
                  *ngIf="
                  maxTwoDecimalPlaceNumericValues &&
                  ((col.translatePrefix + '.' + data[col.prop])?.length <
                    100 || data[col.prop]?.length < 100)
                "
                  class="rw__data-val"
                  [ngClass]="{ 'text-primary': (col?.frozen || col?.link) && col?.items?.length}"
                >
                  {{
                    col.disableNumberFormatting ?
                      (data[col.prop])
                      :
                      (data[col.prop] | number:'1.2-2')
                  }}
                </div>
                <div
                  *ngIf="
                  !maxTwoDecimalPlaceNumericValues &&
                  ((col.translatePrefix + '.' + data[col.prop])?.length <
                    100 || data[col.prop]?.length < 100)
                "
                  class="rw__data-val"
                  [ngClass]="{ 'text-primary': (col?.frozen || col?.link) && col?.items?.length}"
                >
                  {{
                    col.disableNumberFormatting ?
                      (data[col.prop])
                      :
                    col.disableValueTranslation ? data[col.prop] : ((col.translatePrefix
                        ? col.translatePrefix + '.' + data[col.prop]
                        : data[col.prop]
                    ) | translate)
                  }}
                </div>
              </ng-template>
              <span
                *ngIf="
                col.hasSubText"
                class="rw__user-role">
                {{ data[col.subTextKey] }}
                </span>
                </div>

                <!-- Show Metadata in tooltip, will only be used in Product Movement Report -->
                <div *ngIf="col.showMetadata && data.metadata">
                  <p-overlayPanel #op2>
                    <div *ngIf="col.metadataIdentifier === metadataIdentifiers.ProductMovementReport">
                      <p *ngFor="let i of data.metadata.package">{{'productMovementReport.metadataPack' | translate: {
                            key: ('productMovementReport.' + data.metadata.key | translate), sku: i.sku, quantity: i.quantity, rate: i.rate
                          }
                        }}</p>
                      <p *ngFor="let i of data.metadata.composite">{{'productMovementReport.metadataComposite' | translate: {
                        key: ('productMovementReport.' + data.metadata.key | translate), sku: i.sku, quantity: i.quantity, rate: i.rate
                      }
                    }}</p>
                    </div>
                  </p-overlayPanel>
                  <rw-button
                    [type]="'grey-transparent'"
                    [size]="'md'"
                    [label]="''"
                    [icon]="'fa-regular fa-info-circle'"
                    [tracker]="'dummy'"
                    [badge]="0"
                    [disabled]="false"
                    [loading]="false"
                    [rounded]="true"
                    [responsive]="false"
                    (click)="op2.toggle($event)"
                  ></rw-button>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </tr>
    </ng-template>
    <!-- //TO-DO: remove this when No Result design finalized -->
    <!-- <ng-template
      pTemplate="emptymessage"
      let-columns
    >
      <tr>
        <td colspan="{{values.length}}">
          <div class="flex align-items-center justify-content-center flex-column">
          <span class="fw-500">{{ 'primeng.emptyFilterMessage' | translate }}</span>
            <span class="w-50">{{'primeng.emptyFilterMessageText' | translate }}</span>
          </div>
        </td>
      </tr>
    </ng-template> -->
  </p-table>
  <div *ngIf="loading" class="custom-loader">
    <i class="fa-duotone fa-spinner-third fa-xl fa-spin"></i>
  </div>
  <div *ngIf="!loading && values && values.length === 0" class="empty-message bg-white">
    <div class="flex align-items-center justify-content-center flex-column rw__p-9">
      <span class="fw-500 rw__mb-1">{{ 'primeng.emptyFilterMessage' | translate }}</span>
        <span class="rw__fs-9 text-grey-60 w-35 text-center">{{'primeng.emptyFilterMessageText' | translate }}</span>
    </div>
  </div>
  <div
    *ngIf="values && values.length > 0 && showPaginator"
    class="rw__datatable-v3-paginator flex md:flex-row flex-column align-items-center justify-content-md-between rw__px-4 rw__py-md-0 rw__py-4 rw__mt-md-0 rw__mt-4"
    [ngClass]="{ rtl: lng === 'ar' }"
  >
    <div
      class="flex align-items-center white-space-nowrap"
    >
      <span
        class="rw__fs-9 rw__me-4"
        >{{ 'noOfResults' | translate }}</span
      >
      <!-- <select
        class="bg-white border-1 rounded-3 rw__fs-9 text-grey-70 text-uppercase rw__direction-left rw__ps-2 rw__py-2"
        [(ngModel)]="numberOfResults"
        (change)="updateNumberOfRows()"
      >
        <option
          *ngFor="let recordCount of recordCounts"
          [value]="recordCount"
        >
          {{ recordCount }}
        </option>
      </select> -->
      <span class="rw__input rw__input-compact minw-0">
        <p-dropdown
        [options]="recordCounts"
        [(ngModel)]="numberOfResults"
        (ngModelChange)="updateNumberOfRows()"
        >
        <ng-template let-recordCount pTemplate="item">
          <div>{{recordCount}}</div>
        </ng-template>
        </p-dropdown>
      </span>

      <!-- <div class="d-flex rw__ms-4">
        <span class="rw__fs-9">
          <span>{{'showing' | translate }}</span> {{ paginatorState.first }} - {{paginatorState.last}}
          <span>{{ 'of' | translate }}</span> {{ totalRecords }}
        </span>
      </div> -->
    </div>
    <div>
      <p-paginator
        #paginator
        class="white-space-nowrap"
        (onPageChange)="handleOnPageChanged($event)"
        [rows]="numberOfResults"
        [totalRecords]="totalRecords"
        [pageLinkSize]="5"
        [showCurrentPageReport]="showCurrentPageReport"
        currentPageReportTemplate="{{ 'showing' | translate }} {first} - {last} {{ 'of' | translate }} {totalRecords}"
      >
    </p-paginator>
    </div>
  </div>
</div>

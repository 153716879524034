import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { Supplier, ISupplierQuery } from '../../inventory/model/supplier';
import { ISuppliersSerachFilter } from '../model/supplier-search-and-filter';
import { CreatedSupplierResult } from '../../inventory/model/created-supplier-result';
import { FeatureFlagService } from './types/feature-flag.service.interface';
import { FeatureFlagEnum } from '../constants/feature-flag.constants';
import {
  mapSupplierQueryParamsToV2,
  mapSupplierQueryToV2,
} from './mapper-function-supplier-v2';

const API_URL = '/api';

@Injectable()
export class SupplierService {
  private suppliers$: BehaviorSubject<Supplier[] | undefined> =
    new BehaviorSubject(undefined);

  constructor(
    private http: HttpClient,
    private readonly featureFlagService: FeatureFlagService,
  ) {}

  getSuppliers(skipLimit?: boolean): Observable<Supplier[]> {
    if (this.suppliersLoaded()) return this.getSuppliersObs();
    let supplierParams = new HttpParams();

    if (!skipLimit) {
      supplierParams = supplierParams.set('offset', '0').set('limit', '100');
    }

    return this.featureFlagService
      .isEnabled(FeatureFlagEnum.PurchaseOrderRevampV2, false)
      .pipe(
        switchMap((isStockControlServiceEnabled) => {
          let apiResponse: Observable<{ result: Supplier[]; total: number }>;
          if (isStockControlServiceEnabled) {
            apiResponse = this.http.get<{ result: Supplier[]; total: number }>(
              `${API_URL}/stock-control/suppliers`,
              { params: supplierParams },
            );
          } else {
            apiResponse = this.http.get<{ result: Supplier[]; total: number }>(
              `${API_URL}/suppliers`,
            );
          }
          return apiResponse.pipe(
            map((response) => {
              const suppliers = response.result.map(
                (supplier) => new Supplier(supplier),
              );
              this.suppliers$.next(suppliers);
              return suppliers;
            }),
          );
        }),
        first(),
      );
  }

  private suppliersLoaded(): boolean {
    return Boolean(this.suppliers$.getValue());
  }

  private getSuppliersObs() {
    return this.suppliers$.asObservable();
  }

  getSuppliersPage(
    query: ISuppliersSerachFilter,
  ): Observable<{ result: Supplier[]; total: number }> {
    const supplierQueryParams = new HttpParams()
      .set('search', query.search || '')
      .set(
        'fromDate',
        query.dates && query.dates.fromDate
          ? query.dates.fromDate.toString()
          : '',
      )
      .set(
        'toDate',
        query.dates && query.dates.toDate ? query.dates.toDate.toString() : '',
      )
      .set('debitAmount', query.debitAmount || '')
      .set('creditAmount', query.creditAmount || '')
      .set('netDueAmount', query.netDueAmount || '')
      .set('offset', query.offset || '0')
      .set('limit', query.limit || '10');
    return this.featureFlagService
      .isEnabled(FeatureFlagEnum.PurchaseOrderRevampV2, false)
      .pipe(
        switchMap((isStockControlServiceEnabled) => {
          if (isStockControlServiceEnabled) {
            const paramV2 = {};
            const paramMapV2 = mapSupplierQueryParamsToV2(query);
            for (const key in paramMapV2) {
              if (paramMapV2[key]) {
                paramV2[key] = paramMapV2[key];
              }
            }
            return this.http.get<{ result: Supplier[]; total: number }>(
              `${API_URL}/stock-control/suppliers`,
              { params: paramV2 },
            );
          }
          return this.http.get<{ result: Supplier[]; total: number }>(
            `${API_URL}/suppliers`,
            {
              params: supplierQueryParams,
            },
          );
        }),
        first(),
      );
  }

  createSupplier(supplier: Supplier) {
    return this.featureFlagService
      .isEnabled(FeatureFlagEnum.PurchaseOrderRevampV2, false)
      .pipe(
        switchMap((isStockControlServiceEnabled) => {
          if (isStockControlServiceEnabled) {
            for (const key in supplier) {
              if (!supplier[key]) {
                delete supplier[key];
              }
            }
            const apiResponse = this.http.post<Supplier>(
              `${API_URL}/stock-control/suppliers`,
              supplier,
            );
            return apiResponse.pipe(
              map((response) => {
                this.pushSupplier(response);

                return {
                  supplier: response,
                  error: undefined,
                };
              }),
            );
          }
          const apiResponse = this.http.post<CreatedSupplierResult>(
            `${API_URL}/suppliers`,
            supplier,
          );
          return apiResponse.pipe(
            map((response) => {
              if (response.supplier) {
                response.supplier = new Supplier(response.supplier);
                this.pushSupplier(response.supplier);
              }
              return response;
            }),
          );
        }),
        first(),
      );
  }

  pushSupplier(supplier: Supplier) {
    if (this.suppliers$.getValue()) {
      this.suppliers$.getValue().push(supplier);
      this.suppliers$.next(this.suppliers$.getValue());
    }
  }

  popSupplier(supplierId: number) {
    if (this.suppliers$.getValue()) {
      const index = this.suppliers$
        .getValue()
        .findIndex((supplier) => supplier.id === supplierId);
      if (index !== -1) {
        this.suppliers$.getValue().splice(index, 1);
        this.suppliers$.next(this.suppliers$.getValue());
      }
    }
  }

  getSupplier(id: number) {
    return this.suppliers$.getValue()?.find((supplier) => supplier.id === id);
  }

  updateSupplierInList(updatedSupplier: Supplier) {
    if (this.suppliers$.getValue()) {
      this.suppliers$.next(
        this.suppliers$.getValue().map((supplier) => {
          if (supplier.id === updatedSupplier.id) {
            // eslint-disable-next-line no-param-reassign
            supplier = updatedSupplier;
          }
          return supplier;
        }),
      );
    }
  }

  getSupplierById(supplierId: number): Observable<Supplier> {
    return this.featureFlagService
      .isEnabled(FeatureFlagEnum.PurchaseOrderRevampV2, false)
      .pipe(
        switchMap((isStockControlServiceEnabled) => {
          let apiResponse: Observable<Supplier>;
          const supplier = this.getSupplier(supplierId);
          if (supplier) {
            return of(supplier);
          }
          if (isStockControlServiceEnabled) {
            apiResponse = this.http.get<Supplier>(
              `${API_URL}/stock-control/suppliers/${supplierId}`,
            );
          } else {
            apiResponse = this.http.get<Supplier>(
              `${API_URL}/suppliers/${supplierId}`,
            );
          }
          return apiResponse.pipe(
            map((supplierData) => {
              if (supplierData) this.pushSupplier(supplierData);
              return supplierData;
            }),
          );
        }),
        first(),
      );
  }

  getSupplierOpeningBalance(supplierId: number): Observable<any> {
    return this.http.get<any>(`${API_URL}/stock-control/suppliers/opening-balance/${supplierId}`)
      .pipe(
        map(response => {
          return {
            openingBalanceAmount: response.openingBalanceAmount,
            openingBalancePaidAmount: response.openingBalancePaidAmount,
            remainingOpeningBalance: response.remainingOpeningBalance
          };
        })
      );
  }

  updateSupplier(updatedSupplier: Supplier): Observable<Supplier> {
    return this.featureFlagService
      .isEnabled(FeatureFlagEnum.PurchaseOrderRevampV2, false)
      .pipe(
        switchMap((isStockControlServiceEnabled) => {
          let apiResponse: Observable<Supplier>;
          if (isStockControlServiceEnabled) {
            updatedSupplier = this.transformSupplier(updatedSupplier);
            apiResponse = this.http.put<Supplier>(
              `${API_URL}/stock-control/suppliers/${updatedSupplier.id}`,
              updatedSupplier,
            );
          } else {
            apiResponse = this.http.put<Supplier>(
              `${API_URL}/suppliers/${updatedSupplier.id}`,
              updatedSupplier,
            );
          }
          return apiResponse.pipe(
            map((supplier) => {
              if (supplier) this.updateSupplierInList(supplier);
              return supplier;
            }),
          );
        }),
        first(),
      );
  }

  private transformSupplier(supplier: Supplier) {
    delete supplier.creditAmount;
    delete supplier.debitAmount;
    delete supplier.totalPaidAmount;
    return supplier;
  }

  // TODO
  getSupplierPaymentById(supplierId: number): Observable<any> {
    return this.http.get(`${API_URL}/suppliers/payments/${supplierId}`);
  }

  getSupplierWithQuery(
    query: ISupplierQuery,
  ): Observable<{ result: Supplier[]; total: number }> {
    return this.featureFlagService
      .isEnabled(FeatureFlagEnum.PurchaseOrderRevampV2, false)
      .pipe(
        switchMap((isStockControlServiceEnabled) => {
          const supplierParams = new HttpParams()
            .set('name', query.name || '')
            .set('offset', query.offset || '0')
            .set('limit', query.limit || '100');
          if (isStockControlServiceEnabled) {
            const paramV2 = {};
            const paramsMappedV2 = mapSupplierQueryToV2(query);
            for (const key in paramsMappedV2) {
              if (paramsMappedV2[key]) {
                paramV2[key] = paramsMappedV2[key];
              }
            }
            return this.http.get<{ result: Supplier[]; total: number }>(
              `${API_URL}/stock-control/suppliers`,
              { params: paramV2 },
            );
          }
          return this.http.get<{ result: Supplier[]; total: number }>(
            `${API_URL}/suppliers`,
            { params: supplierParams },
          );
        }),
        first(),
      );
  }

  getSupplierWithSearchQuery(
    query: ISupplierQuery,
  ): Observable<{ result: Supplier[]; total: number }> {
    const supplierParams = new HttpParams()
      .set('search', query.name || '')
      .set('offset', query.offset || '0')
      .set('limit', query.limit || '100');
    return this.featureFlagService
      .isEnabled(FeatureFlagEnum.PurchaseOrderRevampV2, false)
      .pipe(
        switchMap((isStockControlServiceEnabled) => {
          if (isStockControlServiceEnabled) {
            const paramV2 = {};
            const paramsMappedV2 = mapSupplierQueryToV2(query);
            for (const key in paramsMappedV2) {
              if (paramsMappedV2[key]) {
                paramV2[key] = paramsMappedV2[key];
              }
            }
            return this.http.get<{ result: Supplier[]; total: number }>(
              `${API_URL}/stock-control/suppliers`,
              { params: paramV2 },
            );
          }
          return this.http.get<{ result: Supplier[]; total: number }>(
            `${API_URL}/suppliers`,
            { params: supplierParams },
          );
        }),
        first(),
      );
  }

  deleteSupplierById(supplierId: number): Observable<boolean> {
    return this.http
      .delete<boolean>(`${API_URL}/suppliers/${supplierId}`, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          if (response.status === 200) {
            this.popSupplier(supplierId);
            return response.body;
          }
          return null;
        }),
      );
  }
}
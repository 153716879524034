import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ButtonTypes, DatatableColumnV3, TableHeadButton, TableHeadFilter } from '../../types';
import { TableDesktopComponent } from '../table-desktop/table-desktop.component';
import { LocalStorageService } from '../../../../../core/services/local-storage.service';
import { LocalStorageKey } from '../../../../constants';

@Component({
  selector: 'rw-filter-tag',
  templateUrl: './filter-tag.component.html',
  styleUrls: ['./filter-tag.component.scss'],
})
export class FilterTagComponent implements AfterViewInit {

  langCss: string;

  clearButtonType: string = ButtonTypes.GreyTransparent;

  @Input() filters?: TableHeadFilter;

  @Input() loading: boolean = false;

  @Input() canApplyFilters: boolean = false;

  @Input() tableDesktop?: TableDesktopComponent;

  @Input() selectedCustomDateFilter: object | undefined;

  @Input() columns?: DatatableColumnV3[];

  @Input() customDateColumn: DatatableColumnV3;

  @Input() dateRangeLimitInMonths: number;

  @Input()
  options: FilterOption[] = [];

  @Input() clearButton: TableHeadButton;

  @Input() showClearButton: boolean = false;

  @Output() customFilterTagUpdated = new EventEmitter();

  @Output() tagRemoved = new EventEmitter<{
    index: number;
    option: FilterOption;
    options: FilterOption[];
  }>();

  @Output() tagFilterApplied: EventEmitter<object> = new EventEmitter<{}>

  @Output() customDateTagRemoved = new EventEmitter();

  autoCompleteField: string = 'name';

  filterTagColumnMap: Map<string, number> = new Map();

  @Output() clearButtonClicked = new EventEmitter<void>();

  constructor(
    public translate: TranslateService,
    private localStorageService: LocalStorageService,
    private elementRef: ElementRef,
    private cd: ChangeDetectorRef,
  ) {
    const lng = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    this.translate.use(lng);
    this.langCss = lng === 'en' ? 'ltr' : 'rtl';
  }

  customChipFilterApplied(event): void {
    const dateRangeObject = event['dateRanges'];
    this.customFilterTagUpdated.emit(dateRangeObject);
  }

  chipFilterApplied(event): void {
    this.tableDesktop.tableFilters = event.tableFilters;
    this.tableDesktop.dateRanges = event.dateRanges;
    this.tableDesktop.autoCompleteSearches = event.autoCompleteSearches;
    this.filters.autoCompleteInputs = event.autoCompleteInputs;

    Object.keys(this.filters.autoCompleteInputs).forEach((key) => {
      if (this.filters.autoCompleteInputs[key]) {
        const obj = { id: this.filters.autoCompleteInputs[key], name: this.filters.autoCompleteInputs[key] };
        this.autoCompleteSelected(key, obj);
      }
    });
    this.tagFilterApplied.emit({
      filters: this.tableDesktop.tableFilters,
      dateRanges: this.tableDesktop.dateRanges,
      autoCompleteSearches: this.tableDesktop.autoCompleteSearches,
    });
    this.cd.detectChanges();
  }

  autoCompleteSelected(colName: string, value): void {
    if (!this.filters.autoCompleteSearches[colName]) {
      this.filters.autoCompleteSearches[colName] = [];
    }
    const exists = this.filters.autoCompleteSearches[colName].find((item) => item.id ? item.id === value.id : item.name === value.name);
    if (!exists) {
      this.filters.autoCompleteSearches[colName].push(value);
      this.tableDesktop.tableFilters[colName] = this.filters.autoCompleteSearches[colName].map(
        (v) => v[this.autoCompleteField],
      );
    }
    this.filters.autoCompleteInputs[colName] = '';
  }

  triggerFilterClick(option: FilterOption, elementId: string): void {
    if (!this.canApplyFilters) {
      return;
    }

    const filterElement = this.elementRef.nativeElement.querySelector('#elementId');
    // const filterElement: HTMLElement = document.querySelector(`[col="${this.columns[this.getColumnIndex(option.key)]}"]`) as HTMLElement;
    const clickEvent: MouseEvent = new MouseEvent('click', { bubbles: true });
    filterElement.dispatchEvent(clickEvent);
    // filterElement.click();
  }


  removeTag(index: number, option: FilterOption): void {
    this.options.splice(index, 1);
    if (option.isCustomDate) {
      this.customDateTagRemoved.emit();
      return;
    }
    this.tagRemoved.emit({ index, option, options: this.options });
    // this.updateFilterTagColumnMap();
  }

  ngAfterViewInit(): void {
    if (!this.canApplyFilters) {
      return;
    }

    if (!this.columns || !this.tableDesktop) {
      throw new Error('Columns and TableDesktop references must be provided as inputs to Filter tag component when `canApplyFilters` is true');
    }
    this.initializeFilterColumnMap();
  }

  private initializeFilterColumnMap(): void {
    this.filterTagColumnMap.clear();
    this.columns?.forEach((column: DatatableColumnV3, index: number): void => {
      this.filterTagColumnMap.set(column.prop, index);
    });
  }

  getColumnIndex(columnProp: string): number {
    return this.filterTagColumnMap.get(columnProp);
  }
}

export interface FilterOption {
  label: string;
  valueName: string;
  value: any[];
  key: string;
  coupled: boolean;
  showDeleteButton: boolean;
  isCustomDate?: boolean;
}

import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { advancedAccountingTranslationPrefix } from '../../advanced-accounting/advanced-accounting.constants';

@Pipe({
  name: 'listToShortenText',
})
export class ListToShortenTextPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(items: { title: string }[]): string {
    if (!items.length) {
      return '';
    }

    const limit = 2;
    const extraCount = items.length - limit;
    const displayedNames = items.map((c) => c.title).slice(0, limit);

    let finalString = displayedNames.join(', ');

    if (extraCount > 0) {
      finalString += this.translateService.instant(
        `${advancedAccountingTranslationPrefix}more`,
        {
          extraCount,
        },
      );
    }
    return finalString;
  }
}

<div class="header" *ngIf="users">
    <div class="rw-logo">
        <a pendo-tracker="uaa" [routerLink]="['/']">
            <img src="/assets/icons/logo.svg" alt="" />
        </a>
    </div>
    <ul class="header-col list-style-list header-action-list">
        <li>
            <p-menu #userMenu [popup]="true" [model]="userMenuItems" appendTo="body"></p-menu>

            <p-button (click)="userMenu.toggle($event)" pRipple iconPos="right"
                styleClass="p-button-secondary p-button-text user-info-button">
                <ng-template pTemplate="content">
                    <div class="user-info-title">
                        <span class="user-info-name">{{ (users?.name) ? users.name : users.email }}</span>
                        <span class="user-info-role">{{users?.SystemRole | translate}}</span>
                    </div>
                    <i class="user-info-icon fa-regular fa-angle-down"></i>
                </ng-template>
            </p-button>
        </li>
    </ul>
</div>

<!-- content -->
<div [ngClass]="langCss" class="unauthorised-access-container">
    <div class="text-center">
        <img [src]="'../../../assets/icons/no-entry.svg'" alt="..."/>
    </div>
    <div class="col text-center mt-4 p-0" *ngIf="!subscriptionEnded">
        <h4>{{ 'You aren\'t allowed to access this page' | translate}}</h4>
        <h6 class="mt-3">{{'Ask the business owner for a permission to be able to access this page' | translate }}</h6>
    </div>
    <div class="col text-center mt-50" *ngIf="subscriptionEnded"><h3>{{'Your subscription has been expired and you don\’t have permission to subscribe to a new plan, please login with account that has a permission to renew your subscription' | translate}}</h3></div>
    <div class="text-center mt-3">
        <p-button [label]="'Take Me Home' | translate" (click)="goHome()"></p-button>
    </div>
</div>
